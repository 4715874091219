import { withYuanUnitAmountFilter } from '@utils/filter'

// 前置内容项配置
export const ADDON_BEFORE_COLUMNS = [
    {
        dataIndex: 'exemptionAmount',
        label: '金额',
        customRender: val => withYuanUnitAmountFilter(val)
    }
]

// 主体内容项配置
export const CONTENT_COLUMNS = [
    {
        dataIndex: 'exemptionAmount',
        label: '免缴金额',
        customRender: val => withYuanUnitAmountFilter(val)
    }
]
